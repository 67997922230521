import cn from 'classnames';
import Button from 'src/components/ui-kit/Button/Button';
import Responsive from '../Responsive/Responsive';
import Slider from '../SliderV2/Slider';
import { useSlider } from '../SliderV2/helpers/useSlider';
import { styles } from './SliderWrapper.styles';
import NavButtons from './components/NavButtons';
import { IProps } from './types';

interface SliderWrapperProps extends IProps {
  titleIsH1?: boolean;
  titleIsCentered?: boolean;
}

export default function SliderWrapper(props: SliderWrapperProps) {
  const {
    withNavButtons = true,
    children,
    title,
    dataMarker,
    mainLink,
    onViewAllClick,
    withMarginTop,
    titleIsH1 = false,
    titleIsCentered = false,
  } = props;


  const { sliderRef, controls, sliderControls } = useSlider({
    fullWidthScroll: true,
    withSwiperMode: false,
  });

  const titleClasses = cn(
    'SliderWrapper__title', {
    'SliderWrapper__title_centered': titleIsCentered,
    'SliderWrapper__title_withMarginTop': withMarginTop,
    'SliderWrapper__title_h1': titleIsH1,
  });

  return (
    <section className='SliderWrapper' data-marker={dataMarker}>
      <div className='SliderWrapper__info'>
        <div className='SliderWrapper__infoLeft'>
          {title && (
            titleIsH1
              ? <h1 className={titleClasses}>{title}</h1>
              : <h2 className={titleClasses}>{title}</h2>
          )}
        </div>

        <Responsive tabletDesktop>
          <div className='SliderWrapper__infoRight'>
            {mainLink && mainLink.href && mainLink.title && (
              <div className='SliderWrapper__infoLinkFull'>
                <Button
                  variant='secondary'
                  fullWidth
                  onClick={onViewAllClick}
                  nextLinkProps={{
                    href: mainLink.href,
                  }}
                  dataMarkerValue='View all'
                >
                  {mainLink.title}
                </Button>
              </div>
            )}
            {withNavButtons && controls && <NavButtons controls={controls} />}
          </div>
        </Responsive>
      </div>

      <Slider
        sliderRef={sliderRef}
        controls={sliderControls}
      >
        {children}
      </Slider>

      <Responsive phone>
        {mainLink && mainLink.href && mainLink.title && (
          <div className='SliderWrapper__mobileLink'>
            <Button
              variant='secondary'
              fullWidth
              nextLinkProps={{
                href: mainLink.href,
              }}
              dataMarkerValue='View all'
            >
              {mainLink.title}
            </Button>
          </div>
        )}
      </Responsive>

      <style jsx>{styles}</style>
    </section>
  );
}
