import css from 'styled-jsx/css';
import { phone, phone_tablet, tablet } from '../../../styles/media';
import typography from '../../../styles/typography';

export const styles = css`
    .SliderWrapper__info {
      display: flex;
      align-items: flex-end;
      margin-bottom: 20px;
      justify-content: space-between;
    }
    .SliderWrapper__item {
      flex-shrink: 0;
      flex-grow: 0;
    }
    .SliderWrapper__title {
      ${typography.h2}
      margin: 7px 0 5px;
      padding: 0;
      text-align: start;
    }
    .SliderWrapper__title:first-letter {
      text-transform: uppercase;
    }
    .SliderWrapper__title_h1 {
      ${typography.h1}
    }
    .NavButtons__infoButtons {
      display: flex;
      flex: 0 0 auto;
    }
    .NavButtons__infoButton {
      margin-left: 10px;
    }
    .SliderWrapper__infoRight {
      display: flex;
      margin-left: auto;
    }
    @media ${phone} {
      .SliderWrapper__info {
        display: flex;
        align-items: flex-end;
        margin-bottom: 20px;
        justify-content: space-between;
      }
      .SliderWrapper__infoLeft {
        width: 100%;
      }
      .SliderWrapper__title {
        ${typography.h2}
        margin: 5px 0;
        padding: 0 10px;
        text-align: start;
      }
      .SliderWrapper__title_withMarginTop{
        margin: 15px 0 5px;
      }
      .SliderWrapper__mobileLink {
        padding: 30px 10px 0 10px;
      }
    }
    @media ${phone_tablet} {
      .SliderWrapper__infoLinkFull {
        margin-right: 20px;
      }
    }
    @media ${tablet} {
      .SliderWrapper__title {
        padding: 0;
        text-align: start;
      }
    }
    .SliderWrapper__title_centered {
      text-align: center;
    }
  `;
